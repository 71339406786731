<template>
  <div class="mapbox">
    <baidu-map
      :style="propStyle"
      class="bm-view"
      @ready="onMapReady"
      :center="center"
      :zoom="zoom"
      dragging
      mapType="BMAP_HYBRID_MAP"
      :scroll-wheel-zoom="true"
      @dblclick="dblclick"
      @moving="syncCenterAndZoom"
      @moveend="syncCenterAndZoom"
      @zoomend="syncCenterAndZoom"
    >
      <!-- 地图切换 -->
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
      <!-- 定位 -->
      <bm-geolocation
        anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
        :showAddressBar="true"
        :autoLocation="true"
        @locationSuccess="locationSuccess"
        @locationError="locationError"
      ></bm-geolocation>
      <!-- 标点 -->
      <template v-if="!height">
        <bm-marker 
        v-for="item in orgList" :key="item.id" 
        :position="{lng:item.longitude,lat:item.dimension}" 
        :dragging="!!height" 
        @dragend="dragend" 
        @click="infoWindowOpen(item)"
        :icon="!item.choose?{url:markerBlue, size: {width: 39, height: 25}}:{url:'https://api.map.baidu.com/images/marker_red_sprite.png', size: {width: 39, height: 25}}"
        >
          <bm-label @click="infoWindowOpen(item)" :content="item.name" :labelStyle="{color: '#fff', fontSize : '12px',border:0,background:'#02004D'}" :offset="{width: 0, height: 30}"/>
        </bm-marker>
      </template>
      <bm-marker v-else :position="{lng: center.lng, lat: center.lat}" :dragging="true">
      </bm-marker>
    </baidu-map>
    <!-- 搜索城市框 -->
    <el-input
      :class="height ? 'cityInput' : 'screencity'"
      v-model="cityCenter"
      placeholder="可输入城市名称快速定位"
      @input="debounceCityChange"
    >
    </el-input>
    <!-- 信息框 -->
    <div v-if="isShow && !height" class="sidebox showinfo">
        <span @click="isShow = false" class="closeicon el-icon-close"></span>
        <div class="Rtitle"><p>{{chooseOrgName}}</p></div>
        <!-- <div class="Rtitle"><p>沿江XJ5标项目部应急物资信息</p></div> -->
      <div style="padding:15px" class="srollhid">
        <div class="goodsInfo" v-for="(item, index) in goodsList" :key="index">
          <div class="paddingbox"  style="border: 1px solid #999;border-bottom: 0;margin-top:10px">
            <img :src="img1" style="width:75px;height:75px"></img>
            <div>
              <p>物资名称</p>
              <p class="goodsfont">{{ item.name }}</p>
            </div>
          </div>
          <div>
            <div class="paddingbox" style="width:50%;border: 1px solid #999;border-bottom: 0;">
              <p>型号</p>
              <p class="goodsfont">{{ item.model }}</p>
            </div>
            <div class="paddingbox" style="width:50%;border: 1px solid #999;border-bottom: 0;">
              <p>数量</p>
              <p class="goodsfont">{{ item.num }}</p>
            </div>
          </div>
          <div>
            <div class="paddingbox" style="width:50%;border: 1px solid #999;border-right:0;">
              <p>责任人</p>
              <p class="goodsfont">{{ item.responsible }}</p>
            </div>
            <div class="paddingbox" style="width:50%;border: 1px solid #999;">
              <p>责任人电话</p>
              <p class="goodsfont">{{ item.responsiblePhone }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 项目部框 -->
    <div v-if="isOrgShow && !height" class="sidebox OrgListbox">
      <div class="tip">
        <span>项目部列表</span>
        <span class="totalnum">({{ total }}个)</span>
      </div>
      <div class="srollhid"> 
        <div :class="item.choose ? 'OrgList OrgListChose' : 'OrgList'"  @click="orgClick(item)" v-for="(item, index) in orgList" :key="index">
          <div :class="item.choose ? 'point pointChose' : 'point'"><p class="order">{{index+1}}</p></div>
          <div class="orgName">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getScreenData } from '@/api/general-user/sceen';
export default {
  props: {
    height: {
      type: String,
      required: false
    },
    center: {
      type: [Object, String],
      default: function () {
        return { lng: 104.06584, lat: 30.65787 }; //机构管理默认成都
      }
    }
  },
  data() {
    return {
      BMap: null,
      map: null,
      // center:{lng: 104.06584, lat: 30.65787},
      /* 在项目部设置经纬度时使用 */
      propStyle: 'height:' + this.height,
      /* 弹窗显隐 */
      isShow: true,
      isOrgShow: true,
      /* 数据 */
      total: 0,
      /* 缩放 */
      zoom: 10,
      cityCenter: '',
      timeout: null,
      orgList: [],
      chooseOrgName: '',
      goodsList: [],
      img1: require('@/assets/images/screen/goods.png'),
      markerBlue: require('@/assets/images/screen/marker_blue.png')
    };
  },
  methods: {
    // ready 事件，返回 BMap 实例
    onMapReady(e) {
      this.BMap = e.BMap;
      this.map = e.map;
    },
    /* 点击点 */
    infoWindowOpen(val) {
      this.orgList.forEach(item => {
        item.choose = false;
        if (item.id === val.id) {
          item.choose = true;
          this.chooseOrgName = item.name;
        }
      });
      this.goodsList = val.emergencyFacilities;
      this.isShow = true;
    },
    /* 定位 */
    locationSuccess(point) {
      if (this.height) {
        this.$emit('getcenter', point.point); //项目部点选经纬度回传
      }
    },
    locationError() {
      this.$message.error('定位失败');
    },
    /* 拖拽标点 */
    dragend(point) {
      if (this.height) {
        this.$emit('getcenter', point.point); //项目部点选经纬度回传
      }
    },
    /* 双击移动点 */
    dblclick(point) {
      if (this.height) {
        this.$emit('getcenter', point.point); //项目部点选经纬度回传
        this.zoom = 18; //最高缩放
      }
    },
    /* 点击机构跳转 */
    orgClick(val) {
      this.$emit('update:center', { lng: val.longitude, lat: val.dimension }); //大屏坐标回传
      this.isShow = true;
      this.goodsList = val.emergencyFacilities;
      this.orgList.forEach(item => {
        item.choose = false;
        if (item.id === val.id) {
          item.choose = true;
          this.chooseOrgName = item.name;
        }
      });
    },
    debounceCityChange() {
      this.debounce(this.cityChange, 500); //注意此处时间
    },
    /* 防抖 */
    debounce(func, delay) {
      let context = this; // this指向发生变化，需要提出来
      let args = arguments;
      return (function () {
        if (context.timeout) {
          clearTimeout(context.timeout);
        }
        context.timeout = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      })(); // 注意:加了()自执行
    },
    /* 输入城市跳转 */
    cityChange() {
      if (this.height) {
        this.$emit('getcenter', this.cityCenter); //大屏坐标回传城市字符串，先定位到城市
        setTimeout(() => {
          this.$emit('getcenter', this.map.getCenter()); //项目部点选经纬度回传城市坐标
        }, 500); //延迟到下一个事件循环执行获取，时间不小于地图跳转延迟时间
      } else {
        this.$emit('update:center', this.cityCenter); //大屏坐标回传城市字符串
      }
    },
    /* 获取项目部物资 */
    getScreenData() {
      getScreenData().then(res => {
          this.total = res.data.length;
          res.data.forEach(item => {
            item.choose = false;
          });
          res.data[0].choose = true;
          this.orgList = res.data;
          var {emergencyFacilities,name,longitude,dimension}=this.orgList[0];
          this.goodsList = emergencyFacilities;
          this.chooseOrgName = name;
          this.$emit('update:center', { lng: longitude, lat: dimension }); //大屏坐标回传
      });
    },
    syncCenterAndZoom(e) {
      setTimeout(() => {
        this.$emit('update:center', this.map.getCenter()); //项目部点选经纬度回传城市坐标
      }, 500); //延迟到下一个事件循环的宏任务执行获取，时间不小于地图跳转延迟时间
      this.zoom = e.target.getZoom();
    }
  },
  mounted() {
    if (!this.height) {
      this.getScreenData();
    }
  }
};
</script>
<style lang="less" scpoed>
.mapbox {
  position: relative;
}
.bm-view {
  width: 100%;
  height: 100vh;
}
/deep/ .maptype {
  margin-top: 15px;
}
.sidebox {
  width: 337px;
  height: calc(100vh - 180px);
  background: rgba(8, 18, 72, 0.9);
  border: 2px solid #0d53b7;
  position: absolute;
  top: 110px;
  color: #fff;
  box-sizing: border-box;
}
.showinfo {
  right: 20px;
  overflow: hidden;
}

.OrgListbox {
  left: 20px;
  padding: 20px 10px;
}
.tip {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.tip::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 26px;
  background: #005aab;
  margin: 0 10px 0 5px;
}
.totalnum {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 14px;
  align-self: flex-end;
  margin-left: 10px;
}
.OrgList {
  width: 319px;
  height: 82px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.OrgListChose {
  background-image: url('~@/assets/images/screen/choseorg.png');
  background-size: 98%;
  background-repeat: no-repeat;
}
.point {
  width: 57px;
  height: 57px;
  background: url('~@/assets/images/screen/point.png') no-repeat;
  position: relative;
}
.pointChose {
  background: url('~@/assets/images/screen/chosepoint.png') no-repeat;
}
.orgName {
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.order {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  margin-left: 3px;
}
.cityInput {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
}
.screencity {
  position: absolute;
  top: 64px;
  left: 22px;
  width: 100px;
}
.closeicon {
  float: right;
  padding: 5px;
  cursor: pointer;
  color: #005aab;
  font-weight: bold;
}
.Rtitle {
  width: 308px;
  height: 38px;
  background: linear-gradient(90deg, #20a6ff, rgba(4, 124, 255, 0));
  p {
    width: 221px;
    height: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold;
    text-align: left;
    color: #ffffff;
    line-height: 17px;
    margin: 0;
    padding-top: 10px;
    margin-top: 15px;
    margin-left: 10px;
  }
}
.goodsInfo {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
  }
}
.paddingbox {
  > p:nth-child(1) {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
}
.goodsfont {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}
.srollhid {
  overflow-y: scroll;
  height: calc(100vh - 270px);
}
.srollhid::-webkit-scrollbar {
  width: 0 !important;
}
</style>
